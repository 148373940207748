@font-face {
  font-family: "Raleway";
  src: url("/fonts/Raleway-VariableFont_wght.ttf") format("truetype");
  font-style: normal;
  font-display: swap;
  font-weight: 200;
}
@font-face {
  font-family: "Raleway";
  src: url("/fonts/Raleway-VariableFont_wght.ttf") format("truetype");
  font-style: normal;
  font-display: swap;
  font-weight: 300;
}
@font-face {
  font-family: "Raleway";
  src: url("/fonts/Raleway-VariableFont_wght.ttf") format("truetype");
  font-style: normal;
  font-display: swap;
  font-weight: 400;
}
@font-face {
  font-family: "Raleway";
  src: url("/fonts/Raleway-VariableFont_wght.ttf") format("truetype");
  font-style: normal;
  font-display: swap;
  font-weight: 500;
}
@font-face {
  font-family: "Raleway";
  src: url("/fonts/Raleway-VariableFont_wght.ttf") format("truetype");
  font-style: normal;
  font-display: swap;
  font-weight: 700;
}
@font-face {
  font-family: "Playfair Display";
  src: url("/fonts/PlayfairDisplay-VariableFont_wght.ttf") format("truetype");
  font-style: normal;
  font-display: swap;
  font-weight: 400;
}
@font-face {
  font-family: "Playfair Display";
  src: url("/fonts/PlayfairDisplay-Italic-VariableFont_wght.ttf")
    format("truetype");
  font-style: italic;
  font-display: swap;
  font-weight: 400;
}
@font-face {
  font-family: "Playfair Display";
  src: url("/fonts/PlayfairDisplay-VariableFont_wght.ttf") format("truetype");
  font-style: normal;
  font-display: swap;
  font-weight: 600;
}
@font-face {
  font-family: "Playfair Display";
  src: url("/fonts/PlayfairDisplay-VariableFont_wght.ttf") format("truetype");
  font-style: normal;
  font-display: swap;
  font-weight: 700;
}

@font-face {
  font-family: "Manrope";
  src: url("/fonts/Manrope-VariableFont_wght.ttf") format("truetype");
  font-style: normal;
  font-display: swap;
  font-weight: 400;
}

@font-face {
  font-family: "Manrope";
  src: url("/fonts/Manrope-VariableFont_wght.ttf") format("truetype");
  font-style: normal;
  font-display: swap;
  font-weight: 700;
}

@font-face {
  font-family: "Bristany";
  src: url("/fonts/Bristany.ttf") format("truetype");
  font-style: normal;
  font-display: swap;
  font-weight: 400;
}
@font-face {
  font-family: "Bristany";
  src: url("/fonts/Bristany.ttf") format("truetype");
  font-style: normal;
  font-display: swap;
  font-weight: 600;
}
@font-face {
  font-family: "Freight Big Book";
  src: url("/fonts/Freight-Big-Book.otf") format("opentype");
  font-style: normal;
  font-display: swap;
  font-weight: 600;
}

@font-face {
  font-family: "Freight Big Book";
  src: url("/fonts/Freight-Big-Book.otf") format("opentype");
  font-style: normal;
  font-display: swap;
  font-weight: 700;
}
