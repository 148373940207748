@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

html,
body {
  padding: 0;
  margin: 0;
  @apply font-sans;
}

a {
  color: inherit;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply tracking-[3px];
}

* {
  box-sizing: border-box;
  @apply font-sans;
  scroll-behavior: smooth;
}
.Section--Heading {
  @apply text-2xl md:text-2xl lg:text-3xl text-center font-bold leading-loose tracking-wide uppercase my-12 mx-auto;
}
.unFixed {
}

.Fixed {
  @apply fixed w-full z-50 opacity-100 shadow-lg;
}

.Fade--In {
  animation: spin 1.8s ease-in-out;
}

.Mobile--Menu a {
  animation: FadeIn 2s;
}

img {
  @apply select-none;
}
header.Fixed {
  @apply bg-white text-black;
}

.container {
  @apply mx-auto px-4 sm:p-8 max-w-screen-2xl;
}
.blob {
  border-radius: 54% 40% 62% 48% / 60% 40% 71% 47%;
}
.Btn--Full {
  @apply duration-200 mt-6  w-full h-16 cursor-pointer
  flex justify-center items-center 
  bg-brand-orange uppercase font-sans font-bold text-xl tracking-widest
  hover:scale-[101%] hover:shadow-md;
}

.Hero--Wrapper {
  @apply px-0 lg:px-12;
  padding-top: 0;
  padding-bottom: 0;
}
.Wrapper {
  @apply py-0;
}
.Sideways--BT {
  @apply rotate-180 -ml-6 text-text-charcoal uppercase text-base;
  writing-mode: vertical-lr;
  text-orientation: sideways;
}

.Sideways--Conf {
  @apply rotate-180  text-text-charcoal uppercase xl:text-lg font-semibold w-full tracking-[1px] order-2;
  writing-mode: vertical-lr;
  text-orientation: sideways;
}

#Course--Preview-Wrapper {
  @apply relative z-40 px-0 lg:px-12;
}

.Section--Background {
  @apply absolute -z-10 h-full w-full;
}
.Section--Background::before {
  @apply absolute left-0  w-full h-16 z-20 bg-white;

  clip-path: ellipse(60% 100% at 50% 0%);
  content: "";
}
.Section--Background::after {
  @apply absolute left-0 bottom-0 w-full z-10 h-full bg-brand-sky;

  clip-path: ellipse(140% 100% at 50% 0%);
  content: "";
}

.signature {
  @apply text-brand-orange font-signature;
}
body,
#Loading--Screen,
.Section--Background::before,
.Dotted--Bkg {
  background-image: -webkit-repeating-radial-gradient(
    center center,
    rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0.1) 1px,
    transparent 1px,
    transparent 100%
  );
  background-image: -moz-repeating-radial-gradient(
    center center,
    rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0.1) 1px,
    transparent 1px,
    transparent 100%
  );
  background-image: -ms-repeating-radial-gradient(
    center center,
    rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0.1) 1px,
    transparent 1px,
    transparent 100%
  );
  background-image: repeating-radial-gradient(
    center center,
    rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0.1) 1px,
    transparent 1px,
    transparent 100%
  );
  -webkit-background-size: 3px 3px;
  -moz-background-size: 3px 3px;
  background-size: 3px 3px;
}

.player .vp-sidedock button {
  @apply hidden;
}
#Course--Modules {
  @apply flex items-center justify-center;
}

.Course--Modules .container {
  @apply px-0 lg:px-4;
}

#Course--Modules .module-heading {
  @apply flex flex-col justify-center lg:flex-row lg:items-center lg:justify-between h-24;
}

#Course--Modules .module-count {
  @apply flex  w-full lg:w-1/12 uppercase font-sans font-bold text-text-gray tracking-widest;
}

#Course--Modules .module-headline {
  @apply my-4 w-full lg:w-10/12 h-[1px] bg-text-charcoal;
}

#Content--Grid {
  @apply hidden lg:flex flex-wrap items-center justify-center 
  lg:grid lg:grid-cols-2 xl:grid-cols-3 gap-x-5 gap-y-8;
}

#Content--Grid .module-container {
  @apply duration-150 ease-in p-2 rounded-3xl min-w-[384px] lg:min-w-fit  mr-2 cursor-pointer
  flex flex-col 
  hover:scale-105 hover:shadow-md hover:bg-[#fefefe] hover:border hover:shadow-brand-orange/10 border-brand-orange/50;
}

#Content--Grid .module-container .thumbnail-container {
  @apply bg-brand-gray h-80 w-full rounded-3xl mb-4;
}

#Content--Grid .module-container .meta-container {
  @apply duration-200 ease-in-out py-4 flex justify-start items-start lg:text-2xl xl:text-3xl font-sans font-bold tracking-widest;
}

#Content--Grid .module-container:hover .meta-container {
  @apply px-1;
}

#Content--Grid .module-container .meta-container .index,
#Content--Grid .module-container .meta-container .title {
  @apply mr-2;
}

#Content--Grid .module-container .meta-container .time {
  @apply ml-auto text-base font-medium p-2 tracking-widest bg-brand-gray
  flex items-center;
}

#Content--Grid .module-container .meta-container .time span {
  @apply text-xs leading-none;
}

#Content--List {
  @apply flex flex-wrap  lg:hidden;
}

#Content--List .module-container {
  @apply duration-150 ease-in p-2 rounded-3xl w-full h-36 mb-8 cursor-pointer
  flex items-stretch
  hover:shadow-md hover:bg-[#fefefe] hover:border hover:shadow-brand-orange/10 border-brand-orange/50;
}

#Content--List .module-container .thumbnail-container {
  @apply w-2/5 bg-brand-gray/50 rounded-3xl mr-8;
}

#Content--List .module-container .meta-container {
  @apply py-2 w-3/5 flex flex-col justify-start items-stretch text-lg sm:text-2xl font-sans font-bold tracking-widest;
}

#Content--List .module-container .meta-container div {
  @apply flex items-start my-1;
}

#Content--List .module-container .meta-container .time {
  @apply text-base font-medium leading-none  tracking-widest 
  bg-brand-gray p-2 mr-auto
  flex items-center;
}

.Social--Media-Icons {
  @apply flex items-center justify-center w-full;
}
.Social--Media-Icon {
  @apply mr-3 relative z-20;
}

/* 
.wrap {
  display: flex;
  align-items: center;
  justify-content: center;
} */

.circle {
  border-radius: 40% 60% 70% 30% / 40% 50% 60% 50%;
  animation: morph 15s ease-in-out infinite;
}

/* .circle img {
  width: 100%;
} */

.Course--Description .container,
.Course--Modules .container {
  @apply py-0;
}

.Login--Page {
  background-image: -webkit-repeating-radial-gradient(
    center center,
    rgba(0, 0, 0, 0.9),
    rgba(0, 0, 0, 0.9) 1px,
    black 1px,
    black 100%
  );
  /* background-image: -moz-repeating-radial-gradient(
    center center,
    rgba(256, 256, 256, 0.1),
    rgba(256, 256, 256, 0.1) 1px,
    1px,
    black 1px,
    black 100%
  );
  background-image: -ms-repeating-radial-gradient(
    center center,
    rgba(256, 256, 256, 0.1),
    rgba(256, 256, 256, 0.1) 1px,
    1px,
    black 1px,
    black 100%
  );
  background-image: repeating-radial-gradient(
    center center,
    rgba(256, 256, 256, 0.1),
    rgba(256, 256, 256, 0.1) 1px,
    black 1px,
    black 100%
  ); */
  -webkit-background-size: 3px 3px;
  -moz-background-size: 3px 3px;
  background-size: 3px 3px;
}

/* Compoenent Styles */
#Breadcrumb.container {
  @apply py-0;
}
#Jumbotron.container {
  @apply py-4;
}
.Desc--Wrapper {
  @apply h-72;
}
.course-card:hover .course-card-top {
  @apply duration-300 ease-in-out opacity-0;
}

.course-card:hover .course-card-bottom {
  @apply duration-300 ease-in-out opacity-100;
}
@keyframes morph {
  0%,
  100% {
    border-radius: 40% 60% 70% 30% / 40% 40% 60% 50%;
  }
  34% {
    border-radius: 70% 30% 50% 50% / 30% 30% 70% 70%;
  }
  67% {
    border-radius: 100% 60% 60% 100% / 100% 100% 60% 60%;
  }
}

@keyframes FadeIn {
  0% {
    @apply opacity-0;
  }

  100% {
    @apply opacity-100;
  }
}

@keyframes fadeIn {
  0% {
    @apply animate-spin;
  }
  34% {
    @apply animate-spin;
  }
  67% {
    @apply animate-spin;
  }
  100% {
    @apply animate-none;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
    opacity: 0%;
  }
  40% {
    transform: rotate(0deg);
    opacity: 100%;
  }
  to {
    transform: rotate(360deg);
    opacity: 100%;
  }
}

#FAQs a {
  @apply underline underline-offset-2 italic decoration-brand-orange;
}
